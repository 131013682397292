$primary-color: #352D39;
$secondary-color: #6D435A;
$third-color: #797D81;
$bg: #e6e2df;

.main-container-box{
    background: $primary-color;
    height: 100vh;
    width: 100%;
    margin: 0;
}

.left{
    border: 1px solid #fff;
    width: 20%;
    margin: 0 15px;
}

// text-folded
//for 3d efffects
//for practice purposes
.wrapper {
	width: 100%;
	font-family: 'Source Code Pro', monospace;
	margin: 0 auto;
	height: 100%;
	
	h1 {
		text-transform: uppercase;
		transform: translate(-50%, -50%) skew(10deg) rotate(-10deg);
		font-size: 20vw;
		top: 50%;
		left: 50%;
		margin: 0;
		position: absolute;
		text-rendering: optimizeLegibility;
		font-weight: 900;
		color: rgba(#ff9eb1, 0.5);
		text-shadow: 1px 4px 6px $bg, 0 0 0 #66303a, 1px 4px 6px $bg;
		white-space: nowrap;
		
		&:before {
			content: attr(data-heading);
			position: absolute;
			left: 0;
			top: -4.8%;
			overflow: hidden;
			width: 100%;
			height: 50%;
			color: #fbf7f4;
			transform: translate(1.6vw, 0) skew(-13deg) scale(1, 1.2);
			z-index: 2;
			text-shadow: 2px -1px 6px rgba(0,0,0,0.2);
		}
		
		&:after {
			content: attr(data-heading);
			position: absolute;
			left: 0;
			top: 0;
			overflow: hidden;
			width: 100%;
			height: 100%;
			z-index: 1;
			color: #d3cfcc;
			transform: translate(0vw, 0) skew(13deg) scale(1, 0.8);
			clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0% 100%);
			text-shadow: 2px -1px 6px rgba(0,0,0,0.3);
		}
	}
}